import { createContext, useContext, useEffect, useState } from "react";
import {
  addFavorite,
  getFavorites,
  removeFavorite,
} from "../services/api/favorites";
import { API } from "../services/api/types";
import { getFavoriteType, isFavorite } from "./favorites";

type AuthContextType = {
  toggleFavorite: (type: string, favorite: API.Favorite) => void;
  favorites: API.Favorites | null;
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  toggleFavorite: () => null,
  login: () => null,
  logout: () => null,
  favorites: {
    players: [],
    managers: [],
    agents: [],
    clubs: [],
  },
});

type AuthContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [favorites, setFavorites] = useState<API.Favorites | null>(null);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  const toggleFavorite = (type: string, favorite: API.Favorite) => {
    if (!favorites) return;

    const favoriteType = getFavoriteType(type);

    const hasFavorite = isFavorite(type, favorite.id, favorites);

    if (hasFavorite) {
      removeFavorite(type, favorite.id).then(() =>
        setFavorites({
          ...favorites,
          [favoriteType as keyof API.Favorites]: favorites[
            favoriteType as keyof API.Favorites
          ].filter((f) => f.id !== favorite.id),
        })
      );
    } else {
      addFavorite(type, favorite.id).then(() =>
        setFavorites({
          ...favorites,
          [favoriteType as keyof API.Favorites]: [
            ...favorites[favoriteType as keyof API.Favorites],
            {
              ...favorite,
              image: favorite.image.replace(
                process.env.NEXT_PUBLIC_ASSET_SERVER_URL || "",
                ""
              ),
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getFavorites().then((res) => setFavorites(res.data));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    setIsAuthenticated(Boolean(token));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        toggleFavorite,
        favorites,
        isAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
