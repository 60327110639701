import classNames from "classnames";
import { MdStar } from "react-icons/md";

const FavoriteIcon = ({ size = 23 }: { size?: number }) => {
  return (
    <div className={classNames(["p-2", "bg-black-light", "rounded-full"])}>
      <MdStar color="#999999" size={size} />
    </div>
  );
};

export default FavoriteIcon;
