import classNames from "classnames";
import { FC } from "react";
import { MdSearch } from "react-icons/md";

type SearchButtonProps = {
  onClick: () => void;
};

const SearchButton: FC<SearchButtonProps> = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames(["p-2", "bg-black-light", "rounded-full"])}
    >
      {/* TODO: Save color in theme*/}
      <MdSearch color="#999999" size={23} />
    </button>
  );
};

export default SearchButton;
