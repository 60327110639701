import classNames from "classnames";
import { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import Container from "../components/Container";
import { mediaStyle } from "../components/Media";
import Navigation from "../components/Navigation";

type Custom400Props = {
  status?: number;
};

const Custom400: NextPage<Custom400Props> = (props) => {
  const status = props.status || 404;

  return (
    <>
      <Head>
        <title>Fotbolltransfers.com</title>
        <meta name="description" content="Fotbolltransfers.com" />

        <style
          type="text/css"
          dangerouslySetInnerHTML={{ __html: mediaStyle }}
        />
      </Head>

      <Container>
        <Navigation />
      </Container>
      <Container horizontalMargin>
        <div className={classNames("mt-5")} />
        <h1
          className={classNames(
            "text-2xl",
            "text-text-primary",
            "font-semibold"
          )}
        >
          {status}
        </h1>
        <div className={classNames("mt-1")} />
        <p className={classNames("text-text-primary")}>
          {status === 404
            ? "Vi kunde tyvärr inte hitta sidan."
            : "Något gick fel."}
        </p>
        <p className={classNames("text-text-primary")}>
          Tryck{" "}
          <Link href="/" passHref>
            <a className={classNames("text-[#006600]")}>här</a>
          </Link>{" "}
          för att komma tillbaka till startsidan.
        </p>
      </Container>
    </>
  );
};

export default Custom400;
