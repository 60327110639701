import classNames from "classnames";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import useBreakpoint from "../../hooks/useBreakpoint";
import { deviceTypeContext } from "../../utils/deviceTypeContext";
import Image from 'next/image';

type SubnNavigationItemType = {
  label: string;
  uri?: string | null;
  style?: string | string[];
  icon?: string;
};

interface NavigationType {
  label: string;
  uri?: string | null;
  icon?: string;
  children?: SubnNavigationItemType[];
}

type MiniMenu = {
  items: NavigationType[];
  onClick?: () => any;
};

const MiniMenu: FC<MiniMenu> = (props) => {
  const [subNavsOpen, setSubNavsOpen] = useState<number[]>([]);

  const isAboveMd = useBreakpoint("md", useContext(deviceTypeContext)!);

  const toggleSubmenu = (i: number) => {
    subNavsOpen.includes(i)
      ? setSubNavsOpen(subNavsOpen.filter((id) => i !== id))
      : setSubNavsOpen([...subNavsOpen, i]);
  };

  const renderItem = (item: NavigationType, i: number) => {
    return (
      <li
        className={classNames(["md:pr-10", "lg:pr-12", "mb-2"])}
        key={item.label}
      >
        <span
          className={classNames(
            {
              "border-b-2": !subNavsOpen.includes(i),
            },
            [
              "uppercase",
              "mb-3",
              "pb-3",
              "inline-block",
              "text-black",
              "font-semibold",
              "md:border-b-0",
              "w-full",
              "flex",
              "justify-between",
            ]
          )}
        >
          {item.uri ? (
            <Link href={item.uri}>
              <a
                className={classNames(
                  "hover:underline",
                  "flex",
                  "place-items-end"
                )}
              >
                {item.icon && (
                  <Image
                    width={24}
                    height={24}
                    className="rounded-full"
                    src={item.icon}
                    alt={item.label}
                  />
                )}
                <span className={classNames({ "ml-3": item.icon })}>
                  {item.label}
                </span>
              </a>
            </Link>
          ) : (
            item.label
          )}
          {item.children && (
            <MdChevronRight
              onClick={() => toggleSubmenu(i)}
              size={22}
              className={classNames({ "-rotate-90": subNavsOpen.includes(i) }, [
                "rotate-90",
                "hover:cursor-pointer",
                "md:hidden",
                "block",
              ])}
            />
          )}
        </span>
        {item.children && (subNavsOpen.includes(i) || isAboveMd) && (
          <ul>
            {item.children.map((subnav: SubnNavigationItemType, z: number) => (
              <li
                key={`${subnav.label} - ${z}`}
                className={classNames([
                  "mb-4",
                  "md:mb-3",
                  "text-base",
                  "md:text-xs",
                  "lg:text-sm",
                  subnav.style,
                ])}
              >
                {subnav.uri ? (
                  <Link href={subnav.uri}>
                    <a className={classNames("hover:underline")}>
                      {subnav.icon && (
                        <Image
                          width={32}
                          height={32}
                          className="rounded-full"
                          src={`/leagues/${subnav.icon}.png`}
                          alt={subnav.label}
                        />
                      )}
                      {subnav.label}
                    </a>
                  </Link>
                ) : (
                  subnav.label
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className={classNames("absolute", "z-10", "left-0", "w-full")}>
      <nav
        className={classNames([
          "md:h-auto",
          "absolute",
          "flex",
          "w-full",
          "bg-black-lightest",
          "pt-5",
          "pb-5",
          "mb:pb-10",
          "border-t-2",
          "h-overlay",
          "md:h-auto",
        ])}
      >
        <div
          className={classNames([
            "flex",
            "h-full",
            "overflow-y-auto",
            "pl-5",
            "pr-3",
            "w-full",
          ])}
        >
          <ul
            className={classNames([
              "flex",
              "flex-col",
              "md:flex-row",
              "w-full",
            ])}
          >
            {props.items.map(renderItem)}
            <li className={classNames("pb-20")}></li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MiniMenu;
