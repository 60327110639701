import classNames from "classnames";
import React, { FC, useContext, useEffect, useState } from "react";
import { MdClose, MdMenu } from "react-icons/md";
import { getLeagues } from "../../data/leagues";
import useBreakpoint from "../../hooks/useBreakpoint";
import { deviceTypeContext } from "../../utils/deviceTypeContext";
import ConditionalRendering from "../ConditionalRendering";
import LeagueMenuButton from "../DropdownMenu/LeagueMenuButton";
import { Media } from "../Media";
import MegaMenu from "../MegaMenu";
import NavigationLink from "./NavigationLink";

type Page = "news" | "transfers" | "about" | "download-app" | "leagues";

type NavigationProps = {
  active?: Page;
};

const Navigation: FC<NavigationProps> = (props) => {
  const leagues = getLeagues();
  const isAboveMd = useBreakpoint("md", useContext(deviceTypeContext)!);

  const [megaMenuState, setMegaMenuState] = useState<boolean>(false);
  useEffect(() => {
    if (megaMenuState && !isAboveMd) {
      window.scrollTo(0, 0);
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [megaMenuState, isAboveMd]);

  const hamburgerIcon = () => (
    <span>
      <button
        className={classNames(
          "h-full",
          "hover:bg-black-lightest-hover",
          "px-5"
        )}
        onClick={() => setMegaMenuState(!megaMenuState)}
      >
        <div
          className={classNames([
            "flex",
            "items-center",
            "justify-center",
            "font-semibold",
          ])}
        >
          {megaMenuState ? <MdClose size={24} /> : <MdMenu size={24} />}
        </div>
      </button>
    </span>
  );

  const menuItems = {
    desktop: (
      <>
        <NavigationLink
          label="Nyheter"
          href="/nyheter"
          isActive={props.active === "news"}
        />
        <NavigationLink
          label="Övergångar"
          href="/transfers/klara-overgangar"
          isActive={props.active === "transfers"}
        />
        <LeagueMenuButton leagues={leagues} />
        {hamburgerIcon()}
      </>
    ),
    mobile: (
      <>
        <NavigationLink
          label="Nyheter"
          href="/nyheter"
          isActive={props.active === "news"}
        />
        <NavigationLink
          label="Övergångar"
          href="/transfers/klara-overgangar"
          isActive={props.active === "transfers"}
        />
        <LeagueMenuButton leagues={leagues} />
        <div className={classNames("ml-auto")}>{hamburgerIcon()}</div>
      </>
    ),
  };

  return (
    <>
      <Media greaterThanOrEqual="md">
        <nav className={classNames(["flex", "w-full", "bg-black-lightest"])}>
          {menuItems.desktop}
        </nav>
      </Media>
      <Media lessThan="md">
        <nav className={classNames(["flex", "w-full", "bg-black-lightest"])}>
          {menuItems.mobile}
        </nav>
      </Media>
      <ConditionalRendering isVisible={megaMenuState}>
        <MegaMenu />
      </ConditionalRendering>
    </>
  );
};

export default Navigation;
