// TODO
// - Handle when there are many results. The list is sometimes way too long.

import React, { FC } from "react";
import classNames from "classnames";
import EntityListItem from "../EntityListItem";
import Link from "next/link";
import slugify from "../../utils/slugify";
import SearchResult from "./SearchResult";

type SearchResultProps = {
  results: FixMeLater[];
  onClick: () => void;
};

const SearchResultDropdown: FC<SearchResultProps> = (props) => {
  return (
    <div
      className={classNames([
        "absolute",
        "w-full",
        "bg-white",
        "shadow-search-result",
        "pb-2",
        "max-h-[800px]",
        "overflow-y-auto",
        "z-50",
      ])}
    >
      <SearchResult results={props.results} onClick={props.onClick} />
    </div>
  );
};

export default SearchResultDropdown;
