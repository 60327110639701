import classNames from "classnames";
import { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import Container from "../components/Container";
import { mediaStyle } from "../components/Media";
import Navigation from "../components/Navigation";

type Custom500Props = {
  errorId?: string;
  status?: number;
};

const Custom500: NextPage<Custom500Props> = (props) => {
  return (
    <>
      <Head>
        <title>Fotbolltransfers.com</title>
        <meta name="description" content="Fotbolltransfers.com" />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{ __html: mediaStyle }}
        />
      </Head>

      <Container>
        <Navigation />
      </Container>
      <Container horizontalMargin>
        <div className={classNames("mt-5")} />
        <h1
          className={classNames(
            "text-2xl",
            "text-text-primary",
            "font-semibold"
          )}
        >
          {props.status || 500}
        </h1>
        <div className={classNames("mt-1")} />
        <p className={classNames("text-text-primary")}>
          Vi har lite problem med våra servrar för tillfället. Om problemet
          kvarstår, kontakta oss{" "}
          <a
            className={classNames("text-[#006600]")}
            href={`mailto:info@fotbolltransfers.com&subject=Felrapport - ${props.errorId}`}
          >
            här
          </a>
          .
        </p>
        <p className={classNames("text-text-primary")}>
          Tryck{" "}
          <Link href="/" passHref>
            <a className={classNames("text-[#006600]")}>här</a>
          </Link>{" "}
          för att komma tillbaka till startsidan.
        </p>
        {props.errorId && (
          <>
            <div className={classNames("mt-5")} />
            <p className={classNames("text-text-secondary")}>
              Fel-id: {props.errorId}
            </p>
          </>
        )}
      </Container>
    </>
  );
};

export default Custom500;
