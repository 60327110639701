import { API } from "../services/api/types";

export const getFavoriteType = (type: string) => {
  return {
    player: "players",
    agent: "agents",
    manager: "managers",
    club: "clubs",
  }[type];
};

export const isFavorite = (
  type: string,
  id: number,
  favorites: API.Favorites
) => {
  const favoriteType = getFavoriteType(type);
  const favoriteIds = {
    players: favorites.players.map((player) => player.id),
    managers: favorites.managers.map((manager) => manager.id),
    agents: favorites.agents.map((agent) => agent.id),
    clubs: favorites.clubs.map((club) => club.id),
  };

  return favoriteIds[favoriteType as keyof API.Favorites].includes(id);
};
