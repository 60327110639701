import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";

type NavigationLinkProps = {
  isActive: boolean;
  label: string;
  href?: string;

  onClick?: () => void;
};

const NavigationLink: FC<NavigationLinkProps> = (props) => {
  const wrap = (children: React.ReactElement) =>
    props.href ? (
      <Link href={props.href} passHref>
        <a>{children}</a>
      </Link>
    ) : (
      <>{children}</>
    );

  return wrap(
    <div
      onClick={props.onClick}
      className={classNames([
        "cursor-pointer",
        "hover:bg-black-lightest-hover",
        "select-none",
      ])}
    >
      <p
        className={classNames([
          "font-semibold",
          "px-4",
          "xs:px-5",
          "py-3",
          "whitespace-nowrap",
        ])}
      >
        {props.label}
      </p>
      <div
        className={classNames({ "bg-green": props.isActive }, [
          "w-full",
          "h-[4px]",
        ])}
      />
    </div>
  );
};

export default NavigationLink;
