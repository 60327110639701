import clientApi from "./clientApi";
import { API } from "./types";

export const getFavorites = () => {
  return clientApi.get<API.Favorites>(`/favorites`);
};

export const addFavorite = (type: string, id: number) => {
  return clientApi.post(`/favorites`, {
    type,
    id,
  });
};

export const removeFavorite = (type: string, id: number) => {
  return clientApi.delete(`/favorites`, {
    data: {
      type,
      id,
    },
  });
};
