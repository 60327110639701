import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";

type Link = {
  label: string;
  route: string;
  icon?: JSX.Element;
};

type NavigationProps = {
  links: Link[];
};

const Navigation: FC<NavigationProps> = (props) => {
  return (
    <nav>
      <ul className="flex">
        {props.links.map((link, index) => {
          const isFirst = index === 0;
          return (
            <li
              key={link.route}
              className={classNames({ "ml-6": !isFirst }, [
                "text-white",
                "text-sm",
                "hover:text-gray-400",
              ])}
            >
              <Link href={link.route} passHref>
                <a className="relative">
                  {link.icon && (
                    <div className="absolute -translate-y-1/2 top-1/2 right-[calc(100%+8px)]">
                      {link.icon}
                    </div>
                  )}
                  {link.label}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
