import { pipe, toLower } from "ramda";
import { replaceAll } from "./replaceAll";

const kebabCase = (string: string) => replaceAll(string, " ", "-");

const removeSpecialCharacters = (string: string) =>
  replaceAll(string, /[^a-zA-Z0-9 ]/g, "");

const normalize = (string: string) => string.normalize("NFD");

const slugify = pipe(toLower, normalize, removeSpecialCharacters, kebabCase);

export default slugify;
