// TODO: Entity refers to a player / manager / club. Come up with a better name for this.

import classNames from "classnames";
import Image from 'next/image';
import React, { FC } from "react";

type EntityListItemProps = {
  avatarSource?: string;
  name: string;
};

const EntityListItem: FC<EntityListItemProps> = (props) => {
  return (
    <div className={classNames(["flex", "items-center"])}>
      <Image
        width={32}
        height={32}
        className="rounded-full object-cover"
        src={
          props.avatarSource
            ? `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}${props.avatarSource}`
            : "/fallback-profile-picture.gif"
        }
        alt={props.name}
      />
      <span
        className={classNames(["ml-3", "font-semibold", "text-text-primary"])}
      >
        {props.name}
      </span>
    </div>
  );
};

export default EntityListItem;
