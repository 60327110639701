import { FC } from "react";

type ConditionalRendering = {
  isVisible: boolean;
};

const ConditionalRendering: FC<ConditionalRendering> = (props) => {
  return (
    <div style={{ display: props.isVisible ? "block" : "none" }}>
      {props.children}
    </div>
  );
};

export default ConditionalRendering;
