import classNames from "classnames";
import React, { FC } from "react";
import { MdSearch } from "react-icons/md";
import SearchResult from "../Header/SearchResult";
import Overlay from "../Overlay";

type SearchModalProps = {
  query: string;
  results: FixMeLater[];
  isLoading: boolean;
  isTyping: boolean;

  onChange: (query: string) => void;
  onClickClose: () => void;
};

const SearchModal: FC<SearchModalProps> = (props) => {
  const onClickClose = (e: FixMeLater) => {
    e.preventDefault();
    props.onClickClose();
  };

  const status = props.isTyping
    ? "isTyping"
    : props.isLoading
    ? "isLoading"
    : props.results.length === 0 && props.query.length > 0
    ? "emptyResult"
    : "successful";

  return (
    <Overlay open onClose={() => {}}>
      <form
        className={classNames([
          "w-full",
          "bg-black",
          "p-4",
          "flex",
          "gap-3",
          "items-center",
        ])}
        onSubmit={(e) => e.preventDefault()}
      >
        <div className={classNames(["relative", "flex-1"])}>
          <input
            autoFocus={true}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.query}
            className={classNames([
              "rounded-full",
              "py-2",
              "pl-10",
              "pr-8",
              "w-full",
              "outline-none",
              "search-input",
            ])}
            placeholder="Sök klubbar, spelare & nyheter"
          />
          <MdSearch
            size={24}
            // Use theme for colors
            color={"#999999"}
            className={classNames([
              "absolute",
              "top-1/2",
              "transform",
              "-translate-y-1/2",
              "left-3",
            ])}
          />
        </div>
        <button
          type="button"
          onClick={onClickClose}
          className={classNames(["text-white", "py-2"])}
        >
          Stäng
        </button>
      </form>
      <div>
        {["isLoading", "isTyping"].includes(status) && (
          <p className={classNames("text-text-primary", "p-5")}>
            Hämtar resultat...
          </p>
        )}
        {status === "emptyResult" && (
          <p className={classNames("text-text-primary", "p-5")}>
            Vi hittade inget på den sökningen
          </p>
        )}
        {status === "successful" && (
          <SearchResult onClick={props.onClickClose} results={props.results} />
        )}
      </div>
    </Overlay>
  );
};

export default SearchModal;
