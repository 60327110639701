import React, { FC } from "react";
import classNames from "classnames";
import Header from "../Header";
import Navigation from "../Navigation";
import Container from "../Container";

const Layout: FC = ({ children }) => {
  return (
    <>
      <Container>
        <Header />
      </Container>
      <main>{children}</main>
    </>
  );
};

export default Layout;
