import React, { FC, useRef } from "react";
import classNames from "classnames";
import { MdClose, MdSearch } from "react-icons/md";
import SearchResult from "./SearchResult";
import { useOnClickOutside } from "../../hooks/onClickOutside";
import SearchResultDropdown from "./SearchResultDropdown";

type SearchProps = {
  query: string;
  results: FixMeLater[];

  onChange: (query: string) => void;
  onBlur: () => void;
  onClickClose: () => void;
  onClickOutside: () => void;
};

const Search: FC<SearchProps> = (props) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);
  useOnClickOutside(formRef, props.onClickOutside);
  const showSearchResults = props.query.length > 0 && props.results?.length > 0;
  const showClearButton = props.query.length > 0;

  const onSubmit = (e: FixMeLater) => {
    e.preventDefault();
  };

  return (
    <form
      onSubmit={onSubmit}
      ref={formRef}
      className={classNames([
        "max-w-[600px]",
        "h-[56px]",
        "flex-1",
        "ml-6",
        "relative",
      ])}
    >
      <div className={classNames(["relative"])}>
        <MdSearch
          size={24}
          className={classNames([
            "top-[50%]",
            "left-3",
            "transform",
            "translate-y-[-50%]",
            "absolute",
            "text-text-secondary",
          ])}
        />
        {showClearButton && (
          <button
            className={classNames([
              "p-0.5",
              "rounded-full",
              "bg-black",
              "hover:bg-black-dark",
              "top-[50%]",
              "right-4",
              "transform",
              "translate-y-[-50%]",
              "absolute",
              "text-text-secondary",
            ])}
          >
            <MdClose
              onClick={props.onClickClose}
              color="white"
              size={20}
              className={classNames([])}
            />
          </button>
        )}
        <input
          ref={inputRef}
          onBlur={props.onBlur}
          value={props.query}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder="Sök klubbar, spelare & nyheter"
          className={classNames(
            { rounded: !showSearchResults, "rounded-t": showSearchResults },
            [
              "bg-black-light",
              "p-4",
              "pl-12",
              "h-full",
              "w-full",
              "outline-none",
              "text-white",
              "placeholder-text-secondary",
              "focus:bg-white",
              "focus:text-text-primary",
            ]
          )}
        ></input>
      </div>
      {showSearchResults && (
        <SearchResultDropdown
          onClick={props.onClickClose}
          results={props.results}
        />
      )}
    </form>
  );
};

export default Search;
