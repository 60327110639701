import classNames from "classnames";
import { MdPersonOutline } from "react-icons/md";

const UserIcon = ({ size = 23 }: { size?: number }) => {
  return (
    <div className={classNames(["p-2", "bg-black-light", "rounded-full"])}>
      <MdPersonOutline color="#999999" size={size} />
    </div>
  );
};

export default UserIcon;
