import classNames from "classnames";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { getSearch } from "../../services/api/search";
import { getFullDate, getWeekDay } from "../../utils/date";
import SearchModal from "../SearchModal";
import Navigation from "./Navigation";
import Search from "./Search";
import SearchButton from "./SearchButton";
import Image from 'next/image';
import { createImageSizes } from '../../utils/image';
import { useAuth } from '../../utils/AuthContext';
import FavoriteIcon from './FavoriteIcon';
import UserIcon from './UserIcon';

const Header: FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [isTypingSearch, setIsTypingSearch] = useState(false);

  const { isAuthenticated } = useAuth();

  const search = async (query: string) => {
    setSearchIsLoading(true);
    await getSearch(query).then(({ data }) => setSearchResults(data));
    setSearchIsLoading(false);
  };

  const resetSearch = () => {
    setSearchResults([]);
    setSearchQuery("");
    setShowSearchModal(false);
  };

  const onBlurSearchInput = () => {};

  useEffect(() => {
    setIsTypingSearch(true);
    if (searchQuery[searchQuery.length - 1] === " ") return;

    setSearchResults([]);
  }, [searchQuery]);

  useDebounce(
    () => {
      setIsTypingSearch(false);

      if (
        searchQuery.length === 0 ||
        searchQuery[searchQuery.length - 1] === " "
      )
        return null;

      search(searchQuery);
    },
    500,
    [searchQuery]
  );

  return (
    <>
      <div className={classNames(["bg-black", "p-4", "md:p-5", "md:pt-6"])}>
        <div
          className={classNames([
            "justify-between",
            "w-full",
            "mb-7",
            "hidden",
            "md:flex",
          ])}
        >
          <p className={classNames(["text-white", "text-sm"])}>
            <span className={classNames(["font-semibold"])}>
              {getWeekDay()}
            </span>
            {` ${getFullDate()}`}
          </p>
          <Navigation
            links={[
              isAuthenticated
              ? {
                label: "För dig",
                route: "/favoriter",
                icon: <FavoriteIcon size={15} />,
              }
              : {
                label: "Logga in",
                route: "/logga-in",
                icon: <UserIcon size={15} />,
              },
              { label: "Kontakt", route: "/nyheter/kontakta-oss/3478" },
              {
                label: "Annonsera",
                route: "/nyheter/vill-du-annonsera-har/26",
              },
            ]}
          />
        </div>

        <div
          className={classNames(["flex", "justify-between", "items-center"])}
        >
          <Link href="/" passHref>
            <a>
              <div className={classNames([
                    "relative",
                    "cursor-pointer",
                    "h-[37px]",
                    "md:h-[56px]",
                    "w-[173px]",
                    "md:w-[311px]",
                  ])}>
                <Image
                  layout='fill'
                  sizes={createImageSizes({
                    mobile: '25vw',
                    tablet: '25vw',
                    desktop: '25vw'
                  })}
                  src="/ft-logo.svg"
                  alt='Fotbolltransfers'
                  className='object-contain object-left'
                />
              </div>
            </a>
          </Link>
          <div
            className={classNames([
              "hidden",
              "md:flex",
              "w-full",
              "justify-end",
            ])}
          >
            {/* TODO: This is a bit confusing. Use isMobile hook for this condition instead */}
            {!showSearchModal && (
              <Search
                onClickOutside={resetSearch}
                onClickClose={resetSearch}
                onBlur={onBlurSearchInput}
                results={searchResults}
                onChange={setSearchQuery}
                query={searchQuery}
              />
            )}
          </div>
          <div className={classNames(["md:hidden", "flex items-center space-x-2"])}>
            {isAuthenticated ? (
              <Link href="/favoriter" passHref>
                <a>
                  <FavoriteIcon />
                </a>
              </Link>
            ) : (
              <Link href="/logga-in" passHref>
                <a>
                  <UserIcon />
                </a>
              </Link>
            )}

            <SearchButton
              onClick={() => {
                setShowSearchModal(true);
                (document.querySelector(".search-input") as any)?.focus();
              }}
            />
          </div>
        </div>
      </div>

      {showSearchModal && (
        <SearchModal
          onClickClose={resetSearch}
          results={searchResults}
          onChange={setSearchQuery}
          query={searchQuery}
          isLoading={searchIsLoading}
          isTyping={isTypingSearch}
        />
      )}
    </>
  );
};

export default Header;
