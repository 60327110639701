import classNames from "classnames";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import useBreakpoint from "../../hooks/useBreakpoint";
import { deviceTypeContext } from "../../utils/deviceTypeContext";

type SubnNavigationItemType = {
  label: string;
  uri?: string | null;
  style?: string | string[];
};

interface NavigationType {
  label: string;
  uri?: string | null;
  children?: SubnNavigationItemType[];
}

const navigation: NavigationType[] = [
  {
    label: "Spelare",
    uri: null,
    children: [
      {
        label: "Dyraste spelare",
        uri: "/spelare/dyraste-spelarna/",
      },
      {
        label: "Svenska landslaget",
        uri: "/klubbar/sverige/svenska-landslaget/5396",
      },
    ],
  },
  {
    label: "Sverige",
    uri: "/klubbar/sverige/",
    children: [
      {
        label: "Allsvenskan",
        uri: "/ligor/sverige/allsvenskan/3",
      },
      {
        label: "Superettan",
        uri: "/ligor/sverige/superettan/4",
      },
      {
        label: "Ettan Norra",
        uri: "/ligor/sverige/ettan-norra/6",
      },
      {
        label: "Ettan Södra",
        uri: "/ligor/sverige/ettan-sodra/7",
      },
      {
        label: "Övriga Sverige",
        uri: "/ligor/sverige/ovriga-sverige/26",
      },
    ],
  },
  {
    label: "England",
    uri: "/klubbar/england/",
    children: [
      {
        label: "Arsenal",
        uri: "/klubbar/england/arsenal/83",
      },
      {
        label: "Chelsea FC",
        uri: "/klubbar/england/chelsea-fc/84",
      },
      {
        label: "Liverpool FC",
        uri: "/klubbar/england/liverpool-fc/82",
      },
      {
        label: "Manchester City",
        uri: "/klubbar/england/manchester-city/132",
      },
      {
        label: "Manchester United",
        uri: "/klubbar/england/manchester-united/81",
      },
    ],
  },
  {
    label: "Spanien",
    uri: "/klubbar/spanien/",
    children: [
      {
        label: "Atlético Madrid",
        uri: "/klubbar/spanien/atletico-madrid/123",
      },
      {
        label: "FC Barcelona",
        uri: "/klubbar/spanien/fc-barcelona/85",
      },
      {
        label: "Real Madrid",
        uri: "/klubbar/spanien/real-madrid/86",
      },
      {
        label: "Sevilla FC",
        uri: "/klubbar/spanien/sevilla-fc/88",
      },
      {
        label: "Valencia CF",
        uri: "/klubbar/spanien/valencia-cf/87",
      },
    ],
  },
  {
    label: "Italien",
    uri: "/klubbar/italien/",
    children: [
      {
        label: "AC Milan",
        uri: "/klubbar/italien/ac-milan/77",
      },
      {
        label: "AS Roma",
        uri: "/klubbar/italien/as-roma/79",
      },
      {
        label: "FC Inter",
        uri: "/klubbar/italien/fc-inter/76",
      },
      {
        label: "Juventus FC",
        uri: "/klubbar/italien/juventus-fc/78",
      },
      {
        label: "SSC Napoli",
        uri: "/klubbar/italien/ssc-napoli/99",
      },
    ],
  },
  {
    label: "Transfers",
    uri: null,
    children: [
      {
        label: "Transferrykten",
        uri: "/nyheter/transferrykten/147117",
      },
      {
        label: "Transferfönstret",
        uri: "/nyheter/nar-oppnar-och-stanger-transferfonstret/1468",
      },
    ],
  },
  {
    label: "Om oss",
    uri: null,
    children: [
      {
        label: "Om Fotbolltransfers.com",
        uri: "/nyheter/om-fotbolltransferscom/25",
      },
      {
        label: "Hur värderar vi",
        uri: "/nyheter/hur-varderar-vi/24",
      },
      {
        label: "Policy",
        uri: "/nyheter/cookies-och-personuppgifter/93473",
      },
      {
        label: "Kontakt",
        uri: "/nyheter/kontakta-oss/3478",
        style: "md:hidden",
      },
      {
        label: "Annonsera",
        uri: "/nyheter/vill-du-annonsera-har/26",
        style: "md:hidden",
      },
      {
        label: "RSS",
        uri: "/rss",
      },
      {
        label: "Native",
        uri: "/native",
      },
    ],
  },
];

const MegaMenu: FC = () => {
  const [subNavsOpen, setSubNavsOpen] = useState<number[]>([]);

  const isAboveMd = useBreakpoint("md", useContext(deviceTypeContext)!);

  const toggleSubmenu = (i: number) => {
    subNavsOpen.includes(i)
      ? setSubNavsOpen(subNavsOpen.filter((id) => i !== id))
      : setSubNavsOpen([...subNavsOpen, i]);
  };

  const renderItem = (item: NavigationType, i: number) => {
    return (
      <li
        className={classNames(["md:pr-10", "lg:pr-12", "mb-2"])}
        key={item.label}
      >
        <span
          className={classNames(
            {
              "border-b-2": !subNavsOpen.includes(i),
            },
            [
              "uppercase",
              "mb-3",
              "pb-3",
              "inline-block",
              "text-black",
              "font-semibold",
              "md:border-b-0",
              "w-full",
              "flex",
              "justify-between",
            ]
          )}
        >
          {item.uri ? (
            <Link href={item.uri}>
              <a className={classNames("hover:underline")}>{item.label}</a>
            </Link>
          ) : (
            item.label
          )}
          <span onClick={() => toggleSubmenu(i)} className={classNames("px-2")}>
            <MdChevronRight
              size={22}
              className={classNames({ "-rotate-90": subNavsOpen.includes(i) }, [
                "rotate-90",
                "hover:cursor-pointer",
                "md:hidden",
                "block",
              ])}
            />
          </span>
        </span>
        {item.children && (subNavsOpen.includes(i) || isAboveMd) && (
          <ul>
            {item.children.map((subnav: SubnNavigationItemType, z: number) => (
              <li
                key={`${subnav.label} - ${z}`}
                className={classNames([
                  "mb-4",
                  "md:mb-3",
                  "text-base",
                  "md:text-xs",
                  "lg:text-sm",
                  subnav.style,
                ])}
              >
                {subnav.uri ? (
                  <Link href={subnav.uri}>
                    <a className={classNames("hover:underline")}>
                      {subnav.label}
                    </a>
                  </Link>
                ) : (
                  subnav.label
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className={classNames("relative", "z-10")}>
      <nav
        className={classNames([
          "md:h-auto",
          "fixed",
          "md:absolute",
          "flex",
          "w-full",
          "bg-black-lightest",
          "pt-5",
          "pb-5",
          "mb:pb-10",
          "border-t-2",
          "h-overlay",
          "md:h-auto",
        ])}
      >
        <div
          className={classNames([
            "flex",
            "h-full",
            "overflow-y-auto",
            "pl-5",
            "pr-3",
            "w-full",
          ])}
        >
          <ul
            className={classNames([
              "flex",
              "flex-col",
              "md:flex-row",
              "w-full",
            ])}
          >
            {navigation.map(renderItem)}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MegaMenu;
