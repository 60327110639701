import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import isYesterday from "dayjs/plugin/isYesterday";
dayjs.extend(isYesterday);

const isToday = (dt: Dayjs, now: Dayjs) => {
  return dt.isSame(now, "day");
};

const leftPad = (n: number | string, width: number, z?: string) => {
  z = z || "0";
  n = n + "";
  return n.length >= width
    ? n
    : new Array(width - n.length + 1).join(z || "") + n;
};

const ignoreTimezone = (date: string | Date) => {
  if (typeof date === "string") return (date as string).replace(".000Z", "");
  return date;
};

const weekdays = [
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lördag",
];

export const ignoreTime = (dateTime: string): string => dateTime.split("T")[0];

export const getWeekDay = () => weekdays[new Date().getDay()];

export const getFullDate = () => format(new Date(), "yyyy-MM-dd");

export const humanizeDate = (
  _date: string,
  options?: {
    soon?: { excludeTime?: boolean };
    later?: { excludeTime?: boolean };
  }
) => {
  const date = dayjs(ignoreTimezone(_date));
  const now = dayjs();
  const h = leftPad(date.hour(), 2);
  const m = leftPad(date.minute(), 2);
  if (isToday(date, now)) {
    if (options?.soon?.excludeTime) return "Idag";
    return `Idag kl ${h}:${m}`;
  }
  if (date.isYesterday()) {
    if (options?.soon?.excludeTime) return "Igår";
    return `Igår kl ${h}:${m}`;
  }
  if (options?.later?.excludeTime) return date.format("YYYY-MM-DD");
  return `${date.format("YYYY-MM-DD")} kl ${h}:${m}`;
};

export const toMysqlDateString = (string: string) =>
  new Date(string).toISOString().slice(0, 19).replace("T", " ");

export const nrOfYears = (date: string) => {
  const partialDate = (date || "").match(/^[0-9]{4}-00-00$/) !== null;

  return dayjs().diff(partialDate ? date.substring(0, 4) : date, "years");
};

export const formatDate = (date: string) => {
  const onlyShowYear = (date || '').match(/^[0-9]{4}-00-00$/) !== null;
  return onlyShowYear
    ? date.substring(0, 4)
    : humanizeDate(date, {
        later: { excludeTime: true },
        soon: { excludeTime: true },
      });
};
