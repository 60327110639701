import classNames from "classnames";
import React, { FC, useContext, useRef, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useOnClickOutside } from "../../hooks/onClickOutside";
import useBreakpoint from "../../hooks/useBreakpoint";
import { deviceTypeContext } from "../../utils/deviceTypeContext";
import { isTouchDevice } from "../../utils/isTouchDevice";
import slugify from "../../utils/slugify";
import ConditionalRendering from "../ConditionalRendering";
import { Media } from "../Media";
import MiniMenu from "../MiniMenu";
import LeagueMenuDesktop from "./LeagueMenuDesktop";

type NavigationLinkProps = {
  leagues: FixMeLater[];
};

const LeagueMenuButton: FC<NavigationLinkProps> = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isAboveMd = useBreakpoint("md", useContext(deviceTypeContext)!);
  const wrapperRef = useRef(null);
  const showMenu = () => {
    if (!isAboveMd) {
      window.scrollTo(0, 0);
      document.body.classList.add("overflow-hidden");
    }
    setIsMenuVisible(true);
  };
  const hideMenu = () => {
    document.body.classList.remove("overflow-hidden");
    setIsMenuVisible(false);
  };
  useOnClickOutside(wrapperRef, isAboveMd ? hideMenu : () => {});

  const interactElementProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = isTouchDevice()
    ? {
        onClick: isMenuVisible ? hideMenu : showMenu,
      }
    : { onMouseEnter: showMenu, onMouseLeave: hideMenu };

  return (
    <>
      <div className={classNames(["md:relative"])} ref={wrapperRef}>
        <div
          {...interactElementProps}
          className={classNames(
            {
              "bg-black-lightest-hover": isMenuVisible,
            },
            [
              "cursor-pointer",
              "select-none",
              "md:hover:bg-black-lightest-hover",
            ]
          )}
        >
          <div
            className={classNames([
              "flex",
              "items-center",
              "font-semibold",
              "px-3",
              "xs:px-5",
              "py-3",
            ])}
          >
            Ligor
            <MdChevronRight
              size={22}
              className={classNames([
                { "rotate-90": isMenuVisible, "-rotate-90": isMenuVisible },
                "ml-[2px]",
                "rotate-90",
              ])}
            />
          </div>
          <div className={classNames(["w-full", "h-[4px]"])} />
        </div>
        <ConditionalRendering isVisible={isMenuVisible}>
          <Media greaterThanOrEqual="md">
            <LeagueMenuDesktop
              onMouseEnter={showMenu}
              onMouseLeave={hideMenu}
              leagues={props.leagues}
              onClickLeague={hideMenu}
            />
          </Media>

          <Media lessThan="md">
            <MiniMenu
              onClick={() => window.scrollTo(0, 0)}
              items={props.leagues.map((l) => {
                let uri = `/ligor/${slugify(l.countryName)}/${slugify(
                  l.title
                )}/${l.id}`;
                if (l.id === 155) uri = `/klubbar/sverige/kontraktslosa/155`;

                return {
                  label: l.title,
                  uri,
                  icon: `/leagues/${l.icon}.png`,
                };
              })}
            />
          </Media>
        </ConditionalRendering>
      </div>
    </>
  );
};

export default LeagueMenuButton;
