// TODO
// - Handle when there are many results. The list is sometimes way too long.

import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import { getClubName } from "../../utils/getClubName";
import { getRouteByEntityType } from "../../utils/getRouteByTagName";
import slugify from "../../utils/slugify";
import EntityListItem from "../EntityListItem";

const sections = [
  {
    label: "Spelare",
    types: ["PLAYER"],
  },
  {
    label: "Tränare",
    types: ["MANAGER"],
  },
  {
    label: "Agenter",
    types: ["AGENT"],
  },
  {
    label: "Klubbar",
    types: ["CLUB"],
  },
  {
    label: "Nyheter",
    types: ["NEWS"],
  },
];

type SearchResultProps = {
  results: FixMeLater[];
  onClick: () => void;
};

const SearchResult: FC<SearchResultProps> = (props) => {
  const populatedSections = sections.map((section) => {
    return {
      ...section,
      result: props.results.filter((data) => section.types.includes(data.type)),
    };
  });

  const renderRow = (row: FixMeLater) => {
    if (["CLUB", "MANAGER", "PLAYER", "AGENT"].includes(row.type))
      return (
        <Link
          href={getRouteByEntityType(
            row.type.toLowerCase(),
            slugify(row.name),
            row.id
          )}
          passHref
        >
          <a
            onClick={props.onClick}
            key={row.id}
            className={classNames([
              "block",
              "pl-[16px]",
              "border-b",
              "border-gray-100",
              "bg-white",
              "hover:bg-gray-100",
              "cursor-pointer",
              "select-none",
              "py-2",
            ])}
          >
            <EntityListItem
              avatarSource={row.imageSmall || row.image}
              name={row.type === "CLUB" ? getClubName(row.name) : row.name}
            />
          </a>
        </Link>
      );

    return (
      <Link href={`/nyheter/${slugify(row.name)}/${row.id}`} passHref>
        <a
          onClick={props.onClick}
          className={classNames([
            "block",
            "p-[16px]",
            "bg-white",
            "hover:bg-gray-100",
            "cursor-pointer",
            "select-none",
            "border-b",
            "border-gray-100",
            "font-bold",
            "text-text-primary",
          ])}
        >
          {row.name}
        </a>
      </Link>
    );
  };

  return (
    <div>
      {populatedSections.map((section) => {
        if (section.result.length === 0) return null;
        return (
          <div key={section.label}>
            <div className={classNames(["flex", "bg-black-lightest"])}>
              <div className={classNames("w-[8px]", "bg-green")} />
              <h3 className={classNames(["p-2", "text-sm", "font-bold"])}>
                {section.label}
              </h3>
            </div>

            {section.result.map(renderRow)}
          </div>
        );
      })}
    </div>
  );
};

export default SearchResult;
