import axios from "axios";
const { NEXT_PUBLIC_API_URL } = process.env;

type FetchResourseOptions = {
  queryParams: { [key: string]: any };
};

export const fetchResource = async <T = any>(
  route: string,
  options?: FetchResourseOptions
) => {
  let url = `${NEXT_PUBLIC_API_URL}${route}`;

  if (options?.queryParams)
    url = `${url}?${new URLSearchParams(options.queryParams)}`;

  return axios.get<T>(url, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateResource = async <T = any>(
  route: string,
  body?: any,
  options?: FetchResourseOptions
): Promise<T | { error: string }> => {
  try {
    let url = `${NEXT_PUBLIC_API_URL}${route}`;
    if (options?.queryParams)
      url = `${url}?${new URLSearchParams(options.queryParams)}`;

    const resp = await axios.post(url, body || {}, {
      headers: { "Content-Type": "application/json" },
    });

    return resp.data;
  } catch (error: FixMeLater) {
    return { error: error.message };
  }
};
