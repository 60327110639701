const getFirstRouteGroup = (type: string) => {
  if (type === "manager") return "tranare";
  if (type === "player") return "spelare";
  if (type === "agent") return "agenter";
  if (type === "club") return "klubbar/land"; // TODO change "land" to countrName
  if (type === "league") return "ligor/land"; // TODO change "land" to countrName

  return "not-found";
};

export const getRouteByEntityType = (
  type: string,
  slug: string,
  id: number
) => {
  return `/${getFirstRouteGroup(type.toLocaleLowerCase())}/${slug}/${id}`;
};
