import classNames from "classnames";
import React, { FC, useEffect } from "react";
import "react-responsive-modal/styles.css";

type OverlayProps = {
  open: boolean;
  onClose: () => void;
};

const Overlay: FC<OverlayProps> = (props) => {
  const onMount = () => {
    document.body.classList.add("overflow-hidden");
  };

  const onUnmount = () => {
    document.body.classList.remove("overflow-hidden");
  };

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  return (
    <div
      className={classNames(
        "fixed",
        "top-0",
        "left-0",
        "right-0",
        "bottom-0",
        "bg-white",
        "z-[1000]",
        "overflow-y-scroll",
        {
          block: props.open,
          hidden: !props.open,
        }
      )}
    >
      {props.children}
    </div>
  );
};

export default Overlay;
