import { createMedia } from "@artsy/fresnel";
import { breakpoints2 } from "../../utils/constants/breakpoints";

const ExampleAppMedia = createMedia({
  breakpoints: breakpoints2,
});

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = ExampleAppMedia;
