const throttle = (callback: () => void, time: number) => {
  let timeoutId: NodeJS.Timeout | null = null;

  return () => {
    if (timeoutId) return;

    timeoutId = setTimeout(() => {
      callback();
      timeoutId = null;
    }, time);
  };
};

export default throttle;
