import { breakpoints2 } from './constants/breakpoints';

export const createImageSizes = ({
    mobile,
    tablet,
    desktop,
  }: {
    mobile: string;
    tablet: string;
    desktop: string;
  }) => {
    const imageSizes = [];
  
    if (mobile) {
      imageSizes.push(`(max-width: ${breakpoints2.sm}px) ${mobile}`);
    }
  
    if (tablet) {
      imageSizes.push(`(max-width: ${breakpoints2.md}px) ${tablet}`);
    }
  
    if (desktop) {
      imageSizes.push(desktop);
    }
  
    return imageSizes.join(", ");
  };