import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";
import slugify from "../../utils/slugify";
import Image from 'next/image';

type LeagueMenuDesktopProps = {
  leagues: FixMeLater[];

  onClickLeague: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const LeagueMenuDesktop: FC<LeagueMenuDesktopProps> = (props) => {
  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={classNames([
        "absolute",
        "left-[73px]",
        "bottom-[4px]",
        "transform",
        "translate-y-full",
        "-translate-x-1/2",
        "grid",
        "grid-rows-2",
        "grid-cols-2",
        "gap-2",
        "w-[437px]",
        "bg-black-lightest",
        "shadow-navigation-dropdown",
        "rounded-sm",
        "overflow-hidden",
        "z-50",
        "py-3",
      ])}
    >
      {props.leagues.map((league) => {
        let href = `/ligor/${slugify(league.countryName)}/${slugify(
          league.title
        )}/${league.id}`;
        // When navigating to kontraktslosa, we instead
        // navigate to the club and not the league
        if (league.id === 155) href = `/klubbar/svrige/kontraktslosa/155`;

        return (
          <Link key={league.id} href={href} passHref>
            <a
              onClick={props.onClickLeague}
              key={league.id}
              className={classNames([
                "flex",
                "items-center",
                "px-5",
                "py-2",
                "hover:bg-black-lightest-hover",
                "cursor-pointer",
                "select-none",
              ])}
            >
              <Image
                width={32}
                height={32}
                className="rounded-full object-cover"
                src={`/leagues/${league.icon}.png`}
                alt={league.title}
              />
              <p
                className={classNames([
                  "ml-2",
                  "font-semibold",
                  "text-text-primary",
                ])}
              >
                {league.title}
              </p>
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default LeagueMenuDesktop;
