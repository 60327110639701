import classNames from "classnames";
import { FC } from "react";

type ContainerProps = {
  horizontalMargin?: boolean;
  opposite?: boolean;
};

const Container: FC<ContainerProps> = (props) => {
  if (props.horizontalMargin)
    return (
      <div className={classNames(props.opposite ? "-mx-4" : "mx-4")}>
        <div className={classNames("container")}>{props.children}</div>
      </div>
    );

  return <div className={classNames("container")}>{props.children}</div>;
};
export default Container;
