import { useEffect, useState } from "react";
import breakpoints from "../utils/constants/breakpoints";
import { DeviceType } from "../utils/getDeviceType";
import throttle from "../utils/throttle";

const getDeviceWidth = (deviceType: DeviceType) => {
  if (deviceType === "mobile") return 300;
  if (deviceType === "tablet") return 770;
  return 1050;
};

const getInitialState = (
  breakpoint: string | number,
  deviceType: DeviceType,
  _deviceWidth: number | null
) => {
  return _isAbove(breakpoint, _deviceWidth || getDeviceWidth(deviceType));
};

const _isAbove = (breakpoint: string | number, width: number): boolean => {
  const bp =
    typeof breakpoint === "number"
      ? { value: breakpoint }
      : Object.values(breakpoints).find((bp) => bp.name === breakpoint);
  if (!bp) return false;

  return width >= bp.value;
};

const useBreakpoint = (
  breakpoint: string | number,
  deviceType: DeviceType
): boolean => {
  const isClient = typeof window !== "undefined";

  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(
    getInitialState(
      breakpoint,
      deviceType,
      isClient ? document.documentElement.clientWidth : null
    )
  );

  const updateMedia = throttle(() => {
    const isAbove = _isAbove(breakpoint, window.innerWidth);
    if (isAboveBreakpoint !== isAbove) setIsAboveBreakpoint(isAbove);
  }, 200);

  useEffect(() => {
    setIsAboveBreakpoint(_isAbove(breakpoint, window.innerWidth));

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [isAboveBreakpoint]);

  return isAboveBreakpoint;
};

export default useBreakpoint;
