export type Breakpoint = {
  name: string;
  value: number;
};

export type Breakpoints = {
  [key: string]: Breakpoint;
};

const breakpoints: Breakpoints = {
  xxs: { name: "xxs", value: 280 },
  xs: { name: "xs", value: 375 },
  sm: { name: "sm", value: 640 },
  md: { name: "md", value: 768 },
  panorama: { name: "panorama", value: 1012 },
  lg: { name: "lg", value: 1024 },
  xl: { name: "xl", value: 1280 },
  xl2: { name: "2xl", value: 1536 },
};

export const breakpoints2 = {
  xxs: 280,
  xs: 375,
  sm: 640,
  md: 768,
  panorama: 1012,
  lg: 1024,
  xl: 1280,
  tws: 1348,
  xl2: 1536,
};

export default breakpoints;
